.react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }
  
  .react-switch {
    cursor: pointer;
    width: 220px;
    height: 40px;
    background: black;
    /* background: #fff; */
    position: relative;
    transition: background-color 0.2s;
    /* border: 1px solid #161a24; */
    border: 1px solid white;
    border-radius: 7px;
  }
  
  .react-switch .react-switch-button {
    position: absolute;
    z-index: 0;
    top: -1px;
    left: -1px;
    width: 110px;
    height: 40px;
    transition: 0.2s;
    background: white;
    /* background: black; */
    /* background: #384054; */
    border-radius: 7px;
    /* box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29); */
  }
  
  .react-switch-checkbox:checked + .react-switch-button {
    left: calc(100% + 1px);
    transform: translateX(-100%);
    /* &.react-switch .react-switch-button {
  
    } */
  }
  
  .react-switch-checkbox:active + .react-switch-button {
    width: 60px;
  }
  
  .react-switch-labels {
    display: relative;
    z-index: 1;
    height: 100%;
    font-style: normal;
    color:"#2F323A";
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
  }
  
  .react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;

    
  }
  .react-switch-labels span:first-child {
    left: 0;
  }
  .react-switch-labels span:last-child {
    right: 0;
  }
  