* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Minomu-Regular",
  sans-serif;

  letter-spacing: 0.04rem;

  


  
}

.example-enter {
  opacity: 0.01;
  transition: opacity .5s ease-in;
}

.example-enter.example-enter-active {
  opacity: 1;
}

.example-leave {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.example-leave.example-leave-active {
  opacity: 0;
}

.link-active {
  color: #bbbbbb;
  text-decoration: none;
}



.alert-enter {
  opacity: 0;
  transition: scale(0.8);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 5000ms, transform 5000ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(1);
  transition: opacity 5000ms, transform 5000ms;
}
